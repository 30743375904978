export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Application',
    route: 'application',
    icon: 'PackageIcon',
  },
  {
    title: 'University Search',
    route: 'University Search',
    icon: 'HomeIcon',
  },
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserIcon',
  },
  {
    title: 'Documents',
    route: 'documents',
    icon: 'FileTextIcon',
  },
  {
    title: 'Services',
    route: 'services',
    icon: 'HomeIcon',
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'ManageApplication',
    route: 'Manage Application',
    icon: 'GridIcon',
  },
  {
    title: 'ExploreSearch',
    route: 'Explore Search',
    icon: 'SearchIcon',
  },
  {
    title: 'Announcements',
    route: 'Announcements',
    icon: 'ClockIcon',
  },
  {
    title: 'To-DoList',
    route: 'To-Do List',
    icon: 'EditIcon',
  },
  {
    title: 'form',
    route: 'form',
    icon: 'EditIcon',
  },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'EditIcon',
  },
  //  {
  //   title: 'Home',
  //   route: 'counselorhome',
  //   icon: 'EditIcon',
  //   user_types: ['counselor']
  // },
  // {
  //   title: 'Analytics',
  //   route: 'analytics',
  //   icon: 'FileIcon',
  //   module_id: 'analytics'
  // },
  // {
  //   title: 'Products',
  //   route: 'products',
  //   icon: 'FileIcon',
  //   module_id: 'products'
  // },
  // {
  //   title: 'Appraisals',
  //   route: 'orders',
  //   icon: 'FileIcon',
  //   module_id: 'orders'
  // },
  // {
  //   title: 'Invoice',
  //   icon: 'FileTextIcon',
  //   route: 'invoice-list',
  //   module_id: 'invoice-list'
  // },
  // {
  //   title: 'Notifications',
  //   route: 'notifications',
  //   icon: 'FileIcon',
  //   module_id: 'notifications'
  // },
  // {
  //   title: 'Contact Us',
  //   route: 'contact-us',
  //   icon: 'FileIcon',
  //   module_id: 'contact-us'
  // },
]

